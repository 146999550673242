<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          {{ record.type + ": " + record.title }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text v-if="record._id">
        <v-row dense>
          <v-col cols="12">
            <v-subheader>{{ $t("Authors") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <th>{{ $t("Author") }}</th>
                  <th>{{ $t("Institution") }}</th>
                </thead>
                <tbody>
                  <tr v-for="(author, ind) in record.authors" :key="'author' + ind">
                    <td class="text-h4">{{ author.author }}</td>
                    <td class="text-h4">{{ author.institution }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Identifiers") }}</v-subheader>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("ISBN") }}</div>
            <div v-if="record.identifiers" class="text-h3">{{ record.identifiers.isbn }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("EISBN") }}</div>
            <div v-if="record.identifiers" class="text-h3">{{ record.identifiers.eisbn }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("ISSN") }}</div>
            <div v-if="record.identifiers" class="text-h3">{{ record.identifiers.issn }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("EISSN") }}</div>
            <div v-if="record.identifiers" class="text-h3">{{ record.identifiers.eissn }}</div>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Availability") }}</v-subheader>
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox v-model="record.availability.library" readonly hide-details dense :label="$t('Library')" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="record.availability.virtual_library"
              readonly
              hide-details
              dense
              :label="$t('Virtual library')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("Years") }}</div>
            <div class="d-flex">
              <div v-for="year in record.years" :key="year.year" class="ml-2">
                <v-chip class="text-h4" small label>{{ year.year }}</v-chip>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("Website") }}</div>
            <div v-if="record.links">
              <a :href="record.links.website">{{ record.links.website }}</a>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      path: "",
      render: 1,
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Didactic resources",
          disabled: false,
          href: "/didactic-resources"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "DidacticResource") {
        this.id = this.$route.params.pathMatch
        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get("infuni_didactic_resources/" + this.id)
        .then(response => {
          this.record = response.data
          //   this.record.employment_years.sort((a, b) => {
          //     return b.year - a.year
          //   })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
